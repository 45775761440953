.link {
	text-decoration: none !important;
	color: $blue !important;
	border-bottom: .1em solid;
	font-family: $futuraMedium;
	letter-spacing: 2px;
	text-transform: lowercase;

	&.link-read-more {
		cursor: pointer;
		text-transform: none;
		color: $orange !important;
		font-size: .8em;
	}
}

