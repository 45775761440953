.btn,
.gform_wrapper .gform_footer input.button, .gform_wrapper .gform_footer input[type=submit] {
	background-color: $blue;
	color: $white;
	padding: .3em 1.5em; 
	font-size: 1.4rem;
	font-family: $futuraMedium;
	text-decoration: none !important;
	transition: background-color .3s;

	&:hover {
		background-color: rgba($blue, .8);
		color: $white;
	}

	&.btn-large {
		text-transform: uppercase;
		font-family: $futuraMedium;		
		display: block;
		text-align: center;
		margin: 1em 0;
		padding: .3em 2em; 
		font-size: 1.7rem;
		letter-spacing: 2px;

		@include xmd {
			display: inline-block;
		}
	}
}