.block {
	padding-top: 1em;
	padding-bottom: 1em;

	a {
		text-decoration: underline;
	}

	&.block-read-more,
	&.block-read-more-all {

		main {
			max-height: 135px;
			overflow: hidden;
			margin-bottom: 1em;

			@include md {
				max-height: 125px;
			}
		}

		&.show {

			main {
				max-height: 100%;
			}
			
		}
	}

	&.block-read-more-all {

		main {
			max-height: 0px;
		}
	}


	&.home-blocks {

		p {
			line-height: 1.3;
		}

		h3 {
			margin-bottom: 0.7em;
		}
		

		article:nth-child(2) {
			color: lighten($dark-gray, 15%);

			header {
				color: lighten($dark-gray, 25%);
			}
			

			a {
				color: lighten($dark-gray, 15%);
			}
		}

	}


	&.block-office-address {

		
		

		p {
			margin-bottom: 0;
		}
	}

	&.block-office-details {
		position: relative;

		@include xmd {
			margin-top:4.1em;
			padding-left: 3em;
			padding-top: 0;
			padding-bottom: 0;

			&:before {
				content: "";
				display: block;
				position: absolute;
				height: 65%;
				width: 2px;
				background-color: $blue;
				top: 7px;
				left: -1em;
			}
		}

	}

	&.surveyor-block {
		padding-top: 0;
		padding-bottom: 1em;
		margin-bottom: 2.5em;
		border-bottom: 1px solid $lightest-gray;

		&:last-child {
			border: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		img {
			margin: 0;
		}

		aside {
			padding-right: 0em;
			margin-bottom: 1em;
		}

		header {

			h3 {
				color: $light-gray;
				font-family: $futuraLight;
				font-size: 2.7rem;
				text-transform: none;
				margin-top: 0;
				margin-bottom: 1.7em;

				small {
					display: block;
					line-height: 1.4;
					color: $blue;
					font-family: $futuraMedium;
					font-size: .6em;
				}
			}
		}

		.surveyor-experience {

			h3 {
				margin-bottom: .5em;
			}
		}
	}

	&.trainer-block {
		padding-top: 0;
		padding-bottom: 1em;
		margin-bottom: 2.5em;
		border-bottom: 1px solid $lightest-gray;

		&:last-child {
			border: none;
			padding-bottom: 0;
			margin-bottom: 0;
		}

		img {
			margin: 0;
		}

		aside {
			padding-right: 0em;
			margin-bottom: 1em;
		}

		header {

			h3 {
				margin-top: 0;
				
			}
		}
	}

	&.table-block {
		margin: 0;
		padding: 2em 0;

		li {
			border-bottom: 1px solid $lightest-gray;
			margin: 0;

			&:last-child {
				border: none;
			}

			.column {
				padding: .3em 1em .3em .2em;
			}
		}
	}

	&.tabs-block {

		main.tabs {
			margin: 0 auto;

			ul.tab-content {
				display: none;
				border-top: 1px solid #ddd;

				margin: 0;
				padding: 2em 0;

				> li {
					border-bottom: 1px solid $lightest-gray;
					margin: 0;

					&:last-child {
						border: none;
					}

					.column {
						padding: .3em 1em .3em .2em;
						display: flex;
						align-items: flex-start;
					}

					ul {
						margin-top: 0;
					}

					.hours {
						font-size: .8em;
						line-height: 2;
					}
				}
			}

			input {
				display: none;
			}

			label {
				display: inline-block;
				margin: 0 0 -1px;
				padding: 15px 25px;
				font-weight: 600;
				text-align: center;
				color: #bbb;
				border: 1px solid transparent;
			}

			label:before {
				display: none;
				font-weight: normal;
				margin-right: 10px;
			}

			label:hover {
				color: #888;
				cursor: pointer;
			}

			input:checked + label {
				color: #555;
				border: 1px solid $lightest-gray;
				border-top: 2px solid $blue;
				border-bottom: 1px solid lighten($lightest-gray, 15%);
			}

			#tab1:checked ~ #day1,
			#tab2:checked ~ #day2,
			#tab3:checked ~ #day3,
			#tab4:checked ~ #day4 {
				display: block;
			}

			@media screen and (max-width: 650px) {
				
				label {
					font-size: 0;
				}

				label[for*='1']:before { content: 'd1'; }
				label[for*='2']:before { content: 'd2'; }
				label[for*='3']:before { content: 'd3'; }
				label[for*='4']:before { content: 'd4'; }
				
				label:before {
					margin: 0;
					font-size: 18px;
					display: block;
				}
			}

			@media screen and (max-width: 400px) {
				
				label {
					padding: 15px;
				}
			}
		}
	}


}
