.wysiwyg {
  font-size: 1.8rem;

  h1 {

  }

  h2 {
    margin-top: 1em;
    text-transform: none;

    small {
      display: block;
      line-height: 1.3;

      @include md {
        display: inline;
      }

    }
  }

  h3 {
    margin-top: 1em;
    font-family: $futuraMedium;
    color: $orange;
  }

  p {

  }

  ul,
  ol {
    display: block;
    margin: 1em .2em 2em;
    padding-left: 1em;
    font-size: 1.8rem;
    line-height: 1.4;

    li {
      padding: .3em 0;
    }
  }

  ul {

    li {
      list-style: disc;
    }
  }

  ol {

    li {
      list-style: decimal;
    }
  }

  strong,
  b {
    font-family: $futuraMedium;
    font-weight: normal;
  }

  .gform_wrapper form,
  form {

    h3.gform_title {
      font-size: 1.6rem;
      font-weight: normal;

      @include sm {
        font-size: 1.8rem;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      font-size: 1.6rem;

      li {
        list-style: none;
        padding: 0em 0 1.5em;

        &.gfield {
          margin: 0;
        }
      }
    }

    input,
    input[type=text],
    input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]),
    textarea {
      border: 1px solid $blue;
      border-radius: 5px;
      padding: .3em 1em;
    }

    .field_sublabel_below .ginput_complex.ginput_container label,
    .field_sublabel_below div[class*=gfield_time_].ginput_container label {
      margin: 9px 0 9px 1px;
    }
  }

  .list-trainings {
    margin: 0;
    padding: 0;
    font-family: $futuraMedium;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    li {
      list-style: none;
      width: 100%;

      a {
        text-decoration: none;
        border-bottom: 1px solid rgba($blue, .7);
        padding-bottom: .1em;
        transition: all .1s;
      }

      small {
        display: block;
        padding: 1rem 2.6rem;
      }

      i {
        font-size: .65em;
        font-weight: bold;
        display: inline-block;
        padding-right: .75em;
        position: relative;
        bottom: .2em;
        transition: all .1s;
      }

      p {
        margin: 0 !important;
      }

      &:hover {

        a {
          border-bottom: 1px solid rgba($blue, .3);
        }

        i {
          bottom: .5em;
          font-size: .7em;
        }
      }
    }
  }
}
