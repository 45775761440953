#site-nav {
	position: absolute;
	z-index: 99;
	width: 100%;
	height: 100vh;
	left: 100vw;
	top: 0;
	background-color: rgba($white, 1);

	transition: left 300ms ease-in-out;

	@include xmd {
		display: block;
		position: static;
		height: auto;
		background-color: transparent;
		left: 0;
		transition: none;
	}

	&.mobile-nav-open {
		transition: left 300ms ease-in-out;
		left: 0;
	}

	.menu-main-menu-container {
		background-color: $lightestBlue;
		padding: 5em 0 1em;
		height: 100%;
		width: 100%;

		@include xmd {
			background-color: transparent;
			padding: 0;
		}

		@include lg {
			padding: 0;
		}

		ul {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			font-size: 1.8rem;
			font-family: $futuraMedium;

			@include xmd {
				flex-direction: row;
				flex-wrap: no-wrap;
				justify-content: space-between;
				margin: 0;
			}

			li {
				padding: .5em 0;

				
				&.current_page_item,
				&.current-menu-parent {

					> a,
					&:after {
						color: $orange;
					}
				}

				&:hover > a,
				&:hover:after,
				&:hover:before {
					color: $orange;
				}

				&:last-child:before {
					display: inline-block;
					content: "\E00C";
					font-family: IMCSfont;
				    font-size: .7em;
					position: relative;	
					padding-right: .5em;

					@include xmd {
						padding-right: 0;
					}
				}

				@include xmd {

					&.current_page_item a,
					&.current-menu-parent a,
					&:hover a,
					&.show-submenu a {
						position: relative;
						z-index: 99999;

						&:before {
							content: "";
							display: block;
							width: 100%;
							height: 3px;
							background-color: $orange;
							border-radius: 10px;
							position: absolute;
							bottom: -0.4em;
							left: 0;
						}
					}

					a {						
						padding: 0 .5em .5em;
					}
				}

				ul.sub-menu {
					display: none;
				}
			}
		}
	}

	.icon-close {
		position: absolute;
		top: 3.5rem;
		left: 2.4rem;
		width: 2.5rem;
		fill: $blue;
		cursor: pointer;

		@include xmd {
			display: none;
		}
	}

	.close-sub-menu {
		position: absolute;
		z-index: 999;
		top: 2.4rem;
		left: 1.9rem;
		fill: $blue;
		cursor: pointer;
		display: none;
		width: 80px;
		height: 30px;

		i {
			font-size: 10px;
		}

		@include xmd {
			display: none !important;
		}
	}
}