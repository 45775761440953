.block.service-sphere-block {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
	position: relative;
	margin-bottom: 7em;

	@include md {
		flex-direction: row;
	}

	article {
		width: 100%;
		margin: 3em 0;

		@include md {			
			padding: 1em 3em 2em 0;
			width: 50%;
			margin: 0;
		}

		@include xmd {
			width: 20%;
		}

		header {
			position: relative;
			cursor: pointer;
			font-size: 1.7rem;
			background-color: $lightBlue;
			width: 12em;
			height: 12em;
			padding: .5em;
			border-radius: 50%;
			display: flex;
			justify-content: center;
			align-items: center;
			text-align: center;
			transition: all .2s ease-in-out;
			margin: 0 auto;

			@include xmd {
				font-size: 1.3rem;
			}

			&:after {
				content: "";
				display: block;
				position: absolute;
				top: -.5em;
				left: -.5em;
				width: 13em;
				height: 13em;
				border-radius: 50%;
				border: 1px solid $lightBlue;
				opacity: 0;
				transition: opacity .3s;
			}

			h2 {
				margin: auto;
				font-size: 1em;
				color: $white;
				font-family: $futuraMedium;
				letter-spacing: 0;
			}
		}

		main {
			display: none;
			height: auto;


			@include md {
				height: 370px;
			}

			// @include xmd {
			// 	height: 200px;
			// }

			&:before {
				content: "";
				display: block;
				width: 1px;
				height: 1.1em;
				background-color: $lightBlue;
				position: absolute;
				margin-top: -1.1em;
				margin: -1.1em auto 0;
				left: 0;
				right: 0;

				@include md {
					height: 55px;
					margin-top: 1.9em;
					margin-left: 24%;
				}

				@include xmd {
					left: initial;
					right: initial;
					height: 59px;
					margin-left: 4.8em;
					margin-top: 1.5em;
				}
			}

			ul,
			div {
			    margin: 3em auto 1em;
			    width: 100%;
				border-top: 1px solid $lightBlue;
				padding: 1em 0;

				display: flex;
			    flex-wrap: wrap;			    
			    flex-direction: column;
			    text-align: center;

			    @include md {
			    	text-align: left;
			    	margin: 0;
					margin-top: 5.3em;
					position: absolute;
					width: 90%;
					left: 5%;

					flex-direction: row;
			    }

			    @include xmd {
			    	margin-top: 5.1em;
			    }  

			    @include xmd {			    	
			    	width: 75%;
			    }

				li {
					padding: .5em 0;
					color: $lightBlue;
					width: 100%;
					
					@include md {
						width: 50%;
					}

					strong {
						display: block;
					}

					span {
						min-height: 1em;
						display: block;
					}
				}
			}
		}

		&:nth-child(2) {
			
			header {
				background-color: $green;

				&:after {
					border-color: $green;
				}
			}

			main {

				&:before {
					background-color: $green;

					@include md {
						margin-left: 71%;
					}

					@include xmd {
						margin-left: 4.8em;
					}
				}

				ul {
					border-color: $green;


					@include md {
						text-align: right;
					}

					@include xmd {
						text-align: left;
					}

					li {
						color: $green;
					}
				}
			}
		}

		&:nth-child(3) {
			
			header {
				background-color: $blue;

				&:after {
					border-color: $blue;
				}
			}

			main {

				&:before {
					background-color: $blue;
				}

				ul {
					border-color: $blue;

					@include xmd {
						text-align: right;
					}

					li {
						color: $blue;
					}
				}
			}
		}

		&:nth-child(4) {
			
			header {
				background-color: $light-gray;

				&:after {
					border-color: $light-gray;
				}
			}

			main {

				&:before {
					background-color: $light-gray;

					@include md {
						margin-left: 71%;
					}

					@include xmd {
						margin-left: 4.8em;
					}
				}

				ul {					
					border-color: $light-gray;

					@include md {
						text-align: right;
					}

					@include xmd {
						margin-left: 5%;
						text-align: right;
					}

					li {
						color: $light-gray;
					}
				}
			}
		}

		&:nth-child(5) {
			
			header {
				background-color: $orange;

				&:after {
					border-color: $orange;
				}
			}

			main {

				&:before {
					background-color: $orange;
				}

				div {					
					border-color: $orange;
					color: $orange;
					font-weight: 600;

					a {
						color: $orange;
					}

					@include xmd {
						margin-left: 12%;
						text-align: right;
						justify-content: flex-end;
					}
				}
			}
		}



		&:hover,
		&.active-sphere {

			header {
				transform: scale(1.2);

				&:after {
					transition: opacity .3s;
					opacity: 1;
				}
			}
		}

		&.active-sphere {

			main {
				display: block;
			}
		}
	}
}