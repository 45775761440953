footer#site-footer {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e3e8ef+27 */
	background: rgba($lightestBlue, .8); /* Old browsers */
	background: -moz-linear-gradient(top,  rgba($lightestBlue, .8) 0%, #e3e8ef 27%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  rgba($lightestBlue, .8) 0%,#e3e8ef 27%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  rgba($lightestBlue, .8) 0%,#e3e8ef 27%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba($lightestBlue, .8)', endColorstr='#e3e8ef',GradientType=0 ); /* IE6-9 */
	overflow: hidden;
	margin-top: -5em;
	position: relative;

	.footer-top {
		padding-top: 10em;

		@include lg {
			display: flex;
			flex-wrap: nowrap;
			flex-direction: row;

			position: relative;
		}

		.menu-footer-menu-container {
			width: 100%;

			@include lg {
				width: 75%;
			}


			ul.footer-menu {
				margin: 0;
				padding: 0;

				@include md {
					display: flex;
					flex-direction: row;
					flex-wrap: wrap;
					position: relative;

					&:before {
						content: "";
						margin: 4em auto -1em;
						display: block;
						width: 100%;
						height: 1px;
						background: $blue;
						border-radius: 50%;
						position: absolute;
						left: 0;
						right: 0;
					}
				}

				@include lg {
					position: static;
				}

				>li {
					padding: 1.5em 1em;

					@include md {
						width: 50%;
					}

					@include xmd {
						width: 25%;
					}

					@include lg {
						width: 25%;
						padding: 1.5em 0em;
					}

					> a {
						font-family: $futuraMedium;
						font-weight: 900;
						letter-spacing: 2px;
						padding: 0.5rem 0;
						text-transform: lowercase;
						font-size: 1.8rem;

						@include md {
							padding: 0 3rem 2rem;
							display: block;
						}

						&:after {
							content: "";
							margin: .5em 0 -1em;
							display: block;
							width: 100%;
							height: 1px;
							background: $blue;
							border-radius: 50%;

							@include md {
								display: none;
							}
						}
					}

					ul {
						margin: 0;
						@include lg {
							padding: 0 0 0 3rem;
						}

						li {
							padding: 0;

							a {
								font-family: $futuraMedium;
								text-transform: none;
								font-size: 1.4rem;
								padding: 0.5rem 0;

								@include lg {
									//padding: 0 0 0 3rem;
								}
							}
						}
					}

					&:nth-child(3) {

						@include md {
							width: 100%;
						}

						@include xmd {
							width: 50%;
						}

						@include lg {
							width: 50%;
						}

						a {

							@include md {
								display: inline;

								&:after {
									display: block;
								}
							}

							@include xmd {
								display: block;

								&:after {
									display: none;
								}
							}
						}


						.sub-menu {							
							column-count: 2;
							column-gap: 0;

							li {
								display: block;

								a {									
									padding-right: 0;
								}
							}
						}
					}
				}
			}
		}

		.footer-contact {
			overflow: hidden;
			padding: 1.5em 1em 2em;
			width: 100%;

			@include xmd {
				width: 50%;
				margin: 0 auto;
				text-align: center;
			}

			@include lg {
				width: 25%;
				text-align: left;
				margin: 0;
				padding: 1.5em 0 2em;
			}

			header {
				display: block;

				@include lg {
					margin-bottom: 1.5em;
				}

				&:after {
					content: "";
					display: block;
					width: 100%;
					height: 1px;
					background: $blue;
					border-radius: 50%;
					margin: .5em 0;

					@include xmd {
						width: 30%;
						margin: .5em auto;
					}

					@include lg {
						display: none;
					}
				}

				h3 {					
					padding: 0 3rem;
					font-family: $futuraMedium;
					font-weight: 900;
					letter-spacing: 2px;
					text-transform: lowercase;					
					font-size: 1.8rem;

					@include lg {
						margin-bottom: 0;
						line-height: 1.5;
					}
				}
			}

			address {
				display: block;
				padding: 0 0 2rem 3rem;
				font-family: $futuraMedium;
				font-weight: 900;
				letter-spacing: 1px;
				font-style: normal;

				@include mobile {
					float: none; 
					width: 100%;
				}
				

				@include sm {
					float: left;
					width: 50%;
				}

				@include xmd {
					text-align: left;
					padding: 0 1.5rem 2rem;

					&:nth-of-type(1) {
						text-align: right;
					}
				}

				@include lg {
					float: none;
					width: 100%;
					text-align: left;
					padding: 0 0 2rem 3rem;

					&:nth-of-type(1) {
						text-align: left;
					}
				}

				span,
				a {
					display: block;
				}
			}
		}
	}

	.footer-bottom {
		margin-bottom: 3em;

		.footer-logo {
			display: block;
			width: 150px;
			margin: 0 auto;
		}

		ul.copy-menu {
			text-align: center;

			li {
				display: inline-block;
				padding: 1em 0 0em;

				// &:nth-child(1) {
				// 	display: block;
				// }

				&:nth-child(1):after {
					content: "-";
					display: inline-block;
					padding: 0 .1em 0 .5em;
				}
			}
		}
	}
}