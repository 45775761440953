.blog-2 {

	#site-nav {

		.menu-main-menu-container {

			ul {

				li {

					&:last-child:before {
						content: "\E00A";
						font-size: 0.9em;
						bottom: -0.2rem;
					}

					&.menu-item-has-children:after {
						cursor: pointer;
						content: "\E007";
						font-family: IMCSfont;
					    font-size: 8px;
						bottom: 0px;
						position: relative;
						padding-left: 2px;

						@include xmd {
							font-size: 5px;
							content: "\E005";
						}
					}

					ul.sub-menu {
						position: absolute;
						z-index: 2;
						left: 100vw;
						top: 0;
						transition: left 300ms ease-in-out;

						width: 100%;
						padding-top: 4.5em;
						padding-bottom: 2.5em;
						text-align: center;
						background-color: $lightestBlue;
						overflow: scroll;
						margin: 0;
						display: block;
						font-family: $futuraLight;


						display: none;

						@include xmd {						
							transition: none;
						}

					}


					&.show-submenu {

						@include xmd {
					    	&:after {
								content: "\E008";
							}
						}

						> a {
							position: absolute;
						    z-index: 5;
						    top: 0;
						    padding-top: 1em;
						    width: 100%;
						    left: 0;
						    text-align: center;
						    font-family: $futuraMedium;
						    font-size: 2.5rem;
						    transition: all 200ms ease-in;

						    @include xmd {
						    	position: relative;
						    	z-index: 99999;
						    	padding: 0 .5em .5em;
						    	font-size: 1.8rem;
						    }

							&:before {
								background-color: $blue;
							}
						}

						&:hover a:before {
							background-color: $orange;
						}

						ul {						
							transition: left 300ms ease-in-out;
							left: 0;
							display: block;

							li:last-child:before {
								display: none;
							}

							@include xmd {
								transition: none;
							    left: 0;
							    display: flex;
							    flex-direction: row;
							    flex-wrap: wrap;
							    align-content: center;
							    justify-content: flex-start;
							    max-height: 170px;
							    z-index: 99;
							    padding: 1.3rem 5rem;
							    text-align: left;
							    margin-top: 2.78em;
							    border-bottom: 1px solid #A3B4C9;
							    
							    li {
									padding: 0 5px;
									width: 25%;

							    	&:first-child {
							    		//padding: 0;
							    	}

							    	&:last-child {

							    		&:after {
							    			display: none;
							    		}
							    	}

							    	&:hover:after {
							    		color: $blue;
							    	}

							    	a {
							    		padding: 0;

							    		&:before {
							    			display: none;
							    		}
							    	}
							    }
							}

							@include lg {								
								padding-left:  calc((100% - 1050px + 8rem)/2 );
								padding-right:  calc((100% - 1050px + 8rem)/2 );
								
								li {
									// width: 33.333%;
								}
							}
						}

						&.menu-item-103 {

							ul {
								flex-direction: row;
								justify-content: center;
								align-items: baseline;
								max-height: 90px;

								li {
									width: 15%;
								}
							}
						}
					}
				}
			}
		}
	}
}



body:not(.home).single-post main#site-main {
	background: #fff;
	background-color: #fff;
}