// 4. Base Typography
// ------------------

$header-font-family: $body-font-family;
$header-font-weight: 300;
$header-font-style: normal;
$header-text-transform: uppercase;
$font-family-monospace: Consolas, 'Liberation Mono', Courier, monospace;
$header-sizes: (
  small: (
    'h1': 24,
    'h2': 30,
    'h3': 16,
    'h4': 18,
    'h5': 17,
    'h6': 16,
  ),
  medium: (
    'h1': 48,
    'h2': 35,
    'h3': 18,
    'h4': 16,
    'h5': 20,
    'h6': 16,
  ),
);
$header-color: inherit;
$header-lineheight: 1.2;
$header-margin-bottom: 0.5em;
$header-text-rendering: optimizeLegibility;
$small-font-size: 70%;
$header-small-font-color: $light-gray;

$paragraph-lineheight: 1.6;
$paragraph-margin-bottom: 1.5em;
$paragraph-text-rendering: optimizeLegibility;

$code-color: $black;
$code-font-family: $font-family-monospace;
$code-font-weight: $global-weight-normal;
$code-background: $light-gray;
$code-border: 1px solid $medium-gray;
$code-padding: rem-calc(2 5 1);

$anchor-color: $blue;
$anchor-color-hover: scale-color($anchor-color, $lightness: -14%);
$anchor-text-decoration: none;
$anchor-text-decoration-hover: none;

$hr-width: $global-width;
$hr-border: 1px solid $medium-gray;
$hr-margin: rem-calc(20) auto;

$list-lineheight: $paragraph-lineheight;
$list-margin-bottom: $paragraph-margin-bottom;
$list-style-type: disc;
$list-style-position: outside;
$list-side-margin: 1.25rem;
$list-nested-side-margin: 1.25rem;

$defnlist-margin-bottom: 1rem;
$defnlist-term-weight: $global-weight-bold;
$defnlist-term-margin-bottom: 0.3rem;

$blockquote-color: $dark-gray;
$blockquote-padding: rem-calc(9 20 0 19);
$blockquote-border: 1px solid $medium-gray;

$cite-font-size: rem-calc(13);
$cite-color: $dark-gray;

$keystroke-font: $font-family-monospace;
$keystroke-color: $black;
$keystroke-background: $light-gray;
$keystroke-padding: rem-calc(2 4 0);
$keystroke-radius: $global-radius;

$abbr-underline: 1px dotted $black;