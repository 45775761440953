$lightestBlue: #F6F7FA;
$lightBlue: #00BFF2;
$blue: #194377;

$green: #888c33;
$orange: #E9931A;

$lightest-gray: #CAD3D9;
$light-gray: #4E6E7F;
$medium-gray: #808080;
$dark-gray: #262A30;

$black: #010101;
$white: #ffffff;

$body-background: $white;
$body-font-color: $blue;

/*** Gradients ***/


$gradient-light-blue:		#70cddd;
$gradient-dark-blue:		#1c75bc;

.blue-bg-gradient {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#70cddd+31,1c75bc+100 */
	background: $gradient-light-blue; /* Old browsers */
	background: -moz-linear-gradient(top,  $gradient-light-blue 31%, $gradient-dark-blue 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top,  $gradient-light-blue 31%,$gradient-dark-blue 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom,  $gradient-light-blue 31%,$gradient-dark-blue 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$gradient-light-blue', endColorstr='$gradient-dark-blue',GradientType=0 ); /* IE6-9 */
}
