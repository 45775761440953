#site-header {
	/* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffffff+0,e3e8ef+27 */
	background: rgba($lightestBlue, .6); /* Old browsers */
	background: -moz-linear-gradient(bottom,  rgba($lightestBlue, .6) 80%, rgba($lightestBlue, 1) 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(bottom,  rgba($lightestBlue, .6) 80%,rgba($lightestBlue, 1) 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to top,  rgba($lightestBlue, .6) 80%,rgba($lightestBlue, 1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='rgba($lightestBlue, .6)', endColorstr='rgba($lightestBlue, 1)',GradientType=0 ); /* IE6-9 */

	.header-top {
		display: flex;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 1rem;
		position: relative;

		@include xmd {
			justify-content: center;
		}

		.mobile-nav {
			font-size: 2.9rem;
			padding: .55em 0 .55em 2.5rem;
			cursor: pointer;

			@include md {
				padding: .55em 0 .55em 4rem;
			}

			@include xmd {
				display: none;
			}
		}

		#site-logo {
			flex: 0 1 auto;
			width: 230px;
			padding-top: .75rem;

			@include md {
				width: 260px;
			}

			@include xmd {
				position: relative;
				z-index: 1;
				padding-top: 0;

				a {

					img {
						margin: 0.2em auto .4em;
						width: 199px;
						display: block;
					}
				}

			}

			a {

				img {
					width: 100%;
				}
			}
		}

		.tagline {
			width: 100%;
			background-color: $blue;
			color: $white;
			padding: 1em;
			text-transform: uppercase;
			font-size: 1.4rem;
			text-align: center;

			span {
				display: block;

				@include md {
					display: inline-block;
				}
			}

			@include xmd {
				background-color: transparent;
				color: $blue;
				position: relative;
				font-size: 1.75rem;
				width: auto;
				text-align: left;

				span {
					margin-top: .5em;
				}
			}
		}
	}

	.header-bottom {		
		background-color: rgba($blue, .05);

		@include xmd {
			position: relative;
			height: 50px;
		}
	}
}