body, html {
	min-width: 320px;
	// overflow-x: hidden;
	height: 100%;
}

body:not(.home) main#site-main {
  background-color: rgba($lightestBlue, .8);
}

.wrapper {
  min-height: 100%;
  /* equal to footer height */
  margin-bottom: -1364px; 
  position: relative;

  @include sm {
  	margin-bottom: -1272px;
  } 

  @include md {
  	margin-bottom: -1101px;
  } 

  @include xmd {
  	margin-bottom: -872px;
  }

  @include lg {
  	margin-bottom: -685px;
  } 
}
.wrapper:after {
  content: "";
  display: block;
}
.wrapper:after {
  height: 1364px;

  @include sm {
  	height: 1272px;
  }

  @include md {
  	height: 1101px;
  }

  @include xmd {
  	height: 872px;
  }

  @include lg {
  	height: 685px;
  }
}
#site-footer {
  background: orange;
  z-index: 0;
}