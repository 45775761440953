.hidden {
	display: none !important;
	visibility: hidden;
}

.overflow-hidden {
	overflow: hidden;
}

.disable-scroll {
	overflow: hidden;
}