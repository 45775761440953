#map-worldwide {
	background-color: rgba($lightestBlue, .8);
	padding: 2em 0;
	margin-top: 0;
	position: relative;

	> .row {		
		position: relative;

		&:before {
			font-size: 1.2rem;

			content: "";
			display: block;
			width: 0;
			height: 0;
			border-left: 0;
			border-right: 4em solid transparent;
			border-bottom: 4em solid rgba($lightestBlue, .8);
			position: absolute;
			top: -6em;
			margin-left: 1.5em;
			margin-left: 2.5rem;

			@include md {
				margin-left: 4rem;
			}
		}
	}

	.world-map {
		position: relative;
		z-index: 1;
	}

	.europe-map {
		position: relative;
		z-index: 0;
	}

	ul.country-marker-list {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;

		li {
			position: absolute;
			cursor: pointer;
			z-index: 1;

			&.show-detail {
				z-index: 5;

				.icon-sphere {
					transform: scale(1.2);
				}

				div {
					transition: opacity .4s;
					display: block;
				}
			}

			.icon-sphere {
				display: block;
				width: .5em;
				height: .5em;
				border-radius: 50%;
				background-color: $orange;
				position: absolute;
				transition: all .3s ease-in-out;

				&:hover {
					transform: scale(1.2);
				}
			}

			div {
				background-color: rgba($white, .9);
				position: relative;
				z-index: 2;
				width: auto;
				padding: .3em .5em;
				line-height: 1.1;
				transition: opacity .4s;
				display: none;

				&:before {
					content: "";
					display: block;
					width: 1px;
					height: 50px;
					position: absolute;

					border-left-style: dotted;
					border-color: $orange;
					border-image-source: url('../img/dots.svg');
					border-image-slice: 33% 33%;
					border-image-repeat: round;
					border-width: 4px;
				}

				.icon-caret-right {
					font-size: 8px;
					display: inline-block;
					padding-left: 1em;
				}

				a {
					font-family: $futuraMedium;
					border-bottom: .1em solid $blue;
				}
			}

			strong {
				color: $orange;
			}

			&.top-right {

				.icon-sphere {
					left: 0;
					top: 0;
				}

				div {
					text-align: left;
					border-left: 1px solid $orange;					
					top: -78px;
					margin-left: 1em;

					&:before {
						bottom: -37px;
						left: -6%;
						transform: rotate(15deg);
					}
				}

			}

			&.top-left {

				.icon-sphere {
					right: 0;
					top: 0;
				}

				div {
					text-align: right;
					border-right: 1px solid $orange;
					top: -84px;
					margin-right: 1em;

					&:before {
						bottom: -37px;
						right: -6%;
						transform: rotate(-15deg);
					}
				}

			}

			&.bottom-right {
				
				.icon-sphere {
					left: 0;
					top: 0;
				}

				div {
					text-align: left;
					border-left: 1px solid $orange;
					bottom: -34px;
					margin-left: 1em;

					&:before {
						bottom: 26px;
						left: -6%;
						transform: rotate(-15deg);
					}
				}
			}

			&.bottom-left {

				.icon-sphere {
					right: 0;
					top: 0;
				}

				div {
					text-align: right;
					border-right: 1px solid $orange;
					bottom: -33px;
					margin-right: 1em;

					&:before {
						bottom: 26px;
						right: -6%;
						transform: rotate(15deg);
					}
				}
			}
		}
	}

	.svg-map {
		width: 100%;
	}

	.world-list,
	.europe-list {
		margin-top: 2em;

		.country-list{
			li{
				display: inline;
				width: auto;

				&:after{
					content: '-';
					display: inline-block;
					margin: auto 0.2rem;
				}

				&:last-child:after{
					display: none;
				}
			}
		}
	}

	.world-list {

		.country-list {
			// column-count: 3;
			// column-gap: 0;
		}
	}

	.europe-list {

		.country-list {
			// column-count: 2;
			// column-gap: 0;
		}
	}
}