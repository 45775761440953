
.hero-header {
  width: 100%;
  overflow: hidden;
  height: 22.5rem;
  position: relative;
  background-size: cover;
  background-position: center 50%;
  background-repeat: no-repeat;
  border-top: 1px solid #A3B4C9;

  h1 {
    color: $white;
    font-size: 5.5rem;
    height: 100%;
    line-height: 1;
    align-items: flex-end;

    @include md {
      font-size: 4.5rem;
    }

    span {
      position: relative;
      bottom: -.16em;
    }
  }
}

.no-hero-header {
  margin: 0 0 -2rem;
  padding: 2em 0 0;
  border-top: 1px solid #A3B4C9;

  h1 {
    font-size: 3.5rem;
    margin-bottom: 0;
    position: relative;

    &:before {
      display: block;
      content: "";
      width: 50%;
      height: 1em;
      opacity: .5;


      position: absolute;
      left: 0;
      bottom: 0.15em;
      z-index: -1;
    }

    @include md {
      font-size: 5.5rem;
    }
  }
}
