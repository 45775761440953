/* 
Fonts

List your fonts in following format:

Titel : styles sperate by ,

A less value from the font you want to list.

*/

/* 
Open Sans : .light,.light-italic,.normal,.normal-italic,.bold,.bold-italic,.extra-bold,.extra-bold-italic
*/

$futuraLight: 'Futura LT W01 Light', sans-serif;
$futuraMedium: 'Futura LT W01 Medium', sans-serif;
$futuraBold: 'Futura LT W01 Bold', sans-serif;